import React, { Component } from 'react';
import Layout from '../../../components/Layout';
import PageHeading from '../../../components/PageHeading/PageHeading';

class CurriculaSeedling extends Component {
  render() {
    return (
      <Layout>
        <PageHeading>AN Curriculum</PageHeading>
        <h1>AN curricula</h1>
      </Layout>
    );
  }
}

export default CurriculaSeedling;
